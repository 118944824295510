.embla {
    position: relative;
    max-width: 45vw;
    margin: auto;
    --slide-height: 90vh;
    --slide-spacing: 10em;
    --slide-size: 100%;
}

@media (max-width: 650px) {
    .embla {
        max-width: 100vw;
        margin: auto;
        --slide-height: 90vh;
        --slide-spacing: 10em;
        --slide-size: 100%;
    }
}

.embla__viewport {
    overflow: hidden;
}
.embla__container {
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
}
.embla__slide__number {
    border-radius: 1.8rem;
    font-size: 4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--slide-height);
    user-select: none;

    div {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
    }
}

.embla__controls {
    position: absolute;
    left: 0;
    right: 0;
    margin-inline: auto;
    bottom: 0;
    width: fit-content;
}
.embla__dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
    -webkit-tap-highlight-color: rgb(25, 222, 159);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 1.8rem;
    height: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.embla__dot:after {
    box-shadow: inset 0 0 0 .2rem rgb(25, 222, 159);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: '';
}
.embla__dot--selected:after {
    box-shadow: inset 0 0 0 .5rem rgb(25, 222, 159);
}
